import { ExtBhDatas } from './extrato-bh-datas';
import { ExtBhResumo } from './extrato-bh-resumo';

export class ExtratoBancoHoras {
    idFirebase: string;
    comp: string;
    funcao: string;
    jornada: string;
    dataI: string;
    dataF: string;
    hComp: string;
    hE: string;
    saldo: string;
    datas: Array<ExtBhDatas>;
    resumoFolha: Array<ExtBhResumo>;

    constructor(idFirebase: string, comp: string, funcao: string, jornada: string, dataI: string, dataF: string, hComp: string, 
        hE: string, saldo: string, datas: Array<ExtBhDatas>, resumoFolha: Array<ExtBhResumo>) {
        this.idFirebase = idFirebase;
        this.comp = comp;
        this.funcao = funcao;
        this.jornada = jornada;
        this.dataI = dataI;
        this.dataF = dataF;
        this.hComp = hComp;
        this.hE = hE;
        this.saldo = saldo;
        this.datas = datas;
        this.resumoFolha = resumoFolha;
    }

    static adapts(obj: any): ExtratoBancoHoras {

        let idFirebase = obj.key;
        let comp = this.getMES(obj.MES) + '/' + obj.ANO;
        let funcao = obj.FUNCAO;
        let jornada = obj.JORNADA;
        let dataI = obj.DATA_I;
        let dataF = obj.DATA_F;
        let hComp = obj.HCOMP;
        let hE = obj.HE;
        let saldo = obj.SALDO;
        let datas = this.adptDatas(obj.Datas);
        let resumoFolha = this.adptResumo(obj.ResumoFolha);

        return new ExtratoBancoHoras(idFirebase, comp, funcao, jornada, dataI, dataF, hComp, hE, saldo, datas, resumoFolha);
    }

    static getMES(data: string): string {
        let newDate = data
        let nome = "--";

        let weekdays = new Array(12);
        weekdays[1] = "jan";
        weekdays[2] = "fev";
        weekdays[3] = "mar";
        weekdays[4] = "abr";
        weekdays[5] = "mai";
        weekdays[6] = "jun";
        weekdays[7] = "jul";
        weekdays[8] = "ago";
        weekdays[9] = "set";
        weekdays[10] = "out";
        weekdays[11] = "nov";
        weekdays[12] = "dez";

        nome = weekdays[newDate];

        return nome;
    }

    static adptDatas(data): Array<ExtBhDatas> {
        let list = new Array<ExtBhDatas>();
        if (data != null && data != undefined && data.length > 0) {
            data.forEach(l => {
                list.push(this.adatpData(l));
            });
        }
        return list;
    }

    static adatpData(obj: any): ExtBhDatas {
        let data = obj.DATA;
        let hrComp = obj.HCOMP;
        let hE = obj.HE;
        let hTot = obj.HTOT;
        let msgFolga = obj.MSGFOLGA;
        let msgGuia = obj.MSGGUIA;
        let saldo = obj.SALDO;
        let seq = obj.SEQ;

        return new ExtBhDatas(data, hrComp, hE, hTot, msgFolga, msgGuia, saldo, seq);
    }

    
    static adptResumo(data): Array<ExtBhResumo> {
        let list = new Array<ExtBhResumo>();
        if (data != null && data != undefined && data.length > 0) {
            data.forEach(l => {
                list.push(this.adatpRes(l));
            });
        }
        return list;
    }

    static adatpRes(obj: any): ExtBhResumo {
        let conta = obj.CONTA;
        let dConta = obj.DCONTA;
        let horas = obj.HORAS;
        let seq = obj.SEQ;

        return new ExtBhResumo(conta, dConta, horas, seq);
    }











}