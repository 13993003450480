import { environment } from 'src/environments/environment';
import { Viagem } from './viagem';

export class Escala {

	idFirebase: string;
	data: string; // Formato para interpretar como data: "YYYY-MM-DD"
	diaSemana: string;
	horaFinalIntervalo: string; // Formato para interpretar como hora: "HH:mm"
	horaInicioIntervalo: string; // Formato para interpretar como hora: "HH:mm"
	horaJornadaFinal: string; // Formato para interpretar como hora: "HH:mm"
	horaJornadaInicial: string; // Formato para interpretar como hora: "HH:mm"
	horaI3: string;
	horaF3: string;
	largada: string;
	pegada: string;
	tabela: string;
	ocorrencia: string;
	labelLinha: string;
	labelJornada: string;
	labelPegada: string;
	labelLargada: string;
	colorTag: string;
	labelObs: string;
	labelVeiculo: string;
	obs: string;
	veiculo: string;
	viagens: Array<Viagem>; // Array do Model Viagem (importado lá em cima)

	// Método construtor do objeto
	constructor(id: string, data: string, horaFinalIntervalo: string, horaInicioIntervalo: string, horaJornadaFinal: string,
		horaJornadaInicial: string, horaI3: string, horaF3: string, largada: string, pegada: string, tabela: string, ocorrencia: string, viagens: Array<Viagem>, diaSemana: string,
		labelLinha: string, labelJornada: string, labelPegada: string, labelLargada: string, colorTag: string, labelObs: string,
		labelVeiculo: string, obs: string, veiculo: string) {

		this.idFirebase = id;
		this.data = data;
		this.horaFinalIntervalo = horaFinalIntervalo;
		this.horaInicioIntervalo = horaInicioIntervalo;
		this.horaJornadaFinal = horaJornadaFinal;
		this.horaJornadaInicial = horaJornadaInicial;
		this.horaI3 = horaI3;
		this.horaF3 = horaF3;
		this.largada = largada;
		this.pegada = pegada;
		this.tabela = tabela;
		this.ocorrencia = ocorrencia;
		this.viagens = viagens;
		this.diaSemana = diaSemana;
		this.labelJornada = labelJornada;
		this.labelLinha = labelLinha;
		this.labelPegada = labelPegada;
		this.labelLargada = labelLargada;
		this.colorTag = colorTag;
		this.labelObs = labelObs;
		this.labelVeiculo = labelVeiculo;
		this.obs = obs;
		this.veiculo = veiculo;
	}

	// Adaptando o objeto retornado pelo Firebase para o nosso Model
	static adapts(obj: any): Escala {
		//let idFirebase = parseInt(obj.key);
		let idFirebase = obj.key;
		let re = "/";
		let data = obj.Data.replace(/re/gi, "-");
		let diaSemana = this.getWeekDay(data);
		let HrIf = obj.HrIf;
		let HrIi = obj.HrIi;
		let HrJf = obj.HrJf;
		let HrJi = obj.HrJi;

		let HrI3 = '';
		let HrF3 = '';

		if (obj.HrI3 != null && obj.HrI3 != undefined) {
			HrI3 = obj.HrI3;
		}

		if (obj.HrJ3 != null && obj.HrJ3 != undefined) {
			HrF3 = obj.HrJ3;
		}

		let largada = obj.Largada;
		let pegada = obj.Pegada;
		let tabela = obj.Tabela;
		let ocorrencia = obj.Ocorrencia;
		let labelLinha = '';
		let labelJornada = '';

		if (environment.config.empresa.emp == 6 ) {
			labelLinha = 'Tabela:'
			labelJornada = '';
		} else {
			labelLinha = 'Linha:';
			labelJornada = 'Horários:';
		}


		let labelPegada = 'Pegada:';
		let labelLargada = 'Largada:';

		if ((obj.Largada == null && obj.Largada == undefined) || (obj.Largada == "")) {
			labelLargada = '';
		}

		if ((obj.Pegada == null && obj.Pegada == undefined) || (obj.Pegada == "")) {
			labelPegada = ' ';
		}

		let labelVeiculo = 'Veículo:';
		let labelObs = 'Observação:';

		let colorTag = 'black';
		if (obj.Ocorrencia != null && obj.Ocorrencia != undefined) {
			labelLinha = 'Ocorrência: ';
			labelJornada = ' ';
			labelPegada = ' ';
			labelLargada = ' ';
			labelVeiculo = ' ';
			labelObs = ' ';
			colorTag = 'red';
			HrIf = '';
			HrIi = '';
			HrJf = '';
			HrJi = '';
			HrI3 = '';
			HrF3 = '';
			largada = '';
			pegada = '';
			tabela = obj.Ocorrencia;

		};
		let obs = obj.Observacoes;
		let veiculo = obj.Veiculo;

		let viagens = this.adaptViagens(obj.Viagens);

		return new Escala(idFirebase, data, HrIf, HrIi, HrJf, HrJi, HrI3, HrF3, largada, pegada, tabela, ocorrencia, viagens, diaSemana,
			labelLinha, labelJornada, labelPegada, labelLargada, colorTag, labelObs, labelVeiculo, obs, veiculo);
	}

	// Retornando o nome do dia da Semana
	static getWeekDay(data: string): string {
		let newDate = new Date(data);
		let nome = "--";

		let weekdays = new Array(7);
		weekdays[0] = "Domingo";
		weekdays[1] = "Segunda-feira";
		weekdays[2] = "Terça-feira";
		weekdays[3] = "Quarta-feira";
		weekdays[4] = "Quinta-feira";
		weekdays[5] = "Sexta-feira";
		weekdays[6] = "Sábado";

		nome = weekdays[newDate.getDay()];

		return nome;
	}

	// Adaptando a lista de viagens que vem do firebase para uma lista do nosso model VIAGEM
	static adaptViagens(data): Array<Viagem> {
		let list = new Array<Viagem>();
		if (data != null && data != undefined && data.length > 0) {
			data.forEach(l => {
				list.push(this.adatpViagem(l));
			});
		}
		return list;
	}


	// Adaptando um objeto de viagem do Firebase para nosso model Viagem
	static adatpViagem(obj: any): Viagem {
		//let idFirebase = parseInt(obj.key);
		let idFirebase = obj.key;
		let horaInicioViagem = obj.HrIv;
		let linha = obj.Linha;
		let saida = obj.Saida;
		let sentido = obj.Sentido;
		let viagem = obj.Viagem;
		let htPt1 = obj.HtPt1;
		let htPt2 = obj.HtPt2;
		let htPt3 = obj.HtPt3;
		let htPt4 = obj.HtPt4;
		let dPt1 = obj.DPt1;
		let dPt2 = obj.DPt2;
		let dPt3 = obj.DPt3;
		let dPt4 = obj.DPt4;
		let nomeLinha = obj.Nome_Linha;
		let letreiro = obj.Letreiro;
		let veiculo = obj.Veiculo;
		let apdvgm = obj.ApdVgm;


		return new Viagem(idFirebase, horaInicioViagem, linha, saida, sentido, viagem, htPt1, htPt2, htPt3, htPt4, dPt1, dPt2, dPt3, dPt4, nomeLinha, letreiro, veiculo, apdvgm);
	}



}