export const environment = {
  production: false,
  config: {
     empresa: {
        emp: 3,
        dominioEmp: '@vtcpoa.com.br',
        emailEmp: 'vtc@vtcpoa.com.br',
        termoPrivacy: 'https://ngs.inf.br/aplicativo/vtc/arquivos/vtcInfo.html',
        htmlVersao: 'https://ngs.inf.br/aplicativo/vtc/arquivos/news',
        htmlInfoMedalha: '',
        versaoApp: '1.1.65',
        nomeApp: 'VTC Info',
        versaoMenu: 'V3',
        nomeEmpresa: 'VTC'
     },
     firebase: {
        apiKey: "AIzaSyDQNoxyLBPlRO8B729KM83F2012w0sNybc",
        authDomain: "appvtc-36eed.firebaseapp.com",
        databaseURL: "https://appvtc-36eed.firebaseio.com",
        projectId: "appvtc-36eed",
        storageBucket: "appvtc-36eed.appspot.com",
        messagingSenderId: "552126206009"
     },
     mensagens: {
        msgRecSenha: 'E-mail não encontrado! Procure a empresa para atualizar o seu e-mail.',
        msgRecSenha2: 'Você receberá em alguns minutos um e-mail com a nova senha de acesso. Qualquer d�vida entrar em contato com o Dpto. TI.'
     },
     style: {
        logoEmpresa: 'https://ngs.inf.br/aplicativo/vtc/imagens/logoEmp.png',
        logoEmpresa2: '',
        iconeApp: 'https://ngs.inf.br/aplicativo/vtc/imagens/icon.png',
        iconeApp2: '',
        corEmpresa: 'color10',
        corMenu: 'color21',
        corMenu1: 'color22',
        corMenu2: 'color21',
        varCSS: 'logoVTC',
        varCSS2: '',
        varCSS3: 'scroll-content',
        varCSS4: 'img-menu-vtc'
     }
  }
};
