import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { AlertController, Platform, PopoverController } from '@ionic/angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from "rxjs/operators";
import { DmgeralService } from './dmgeral';
import { Device } from '@ionic-native/device/ngx';
import { environment } from 'src/environments/environment';
import { Funcionario } from 'src/models/funcionario';
import { Menu } from 'src/models/menu';
import { Id } from 'src/models/id';
import { Versao } from 'src/models/versao';
import { Noticias } from 'src/models/noticias';
import { Correio } from 'src/models/correio';
import { NewNotification } from 'src/models-new/new-notification';
import { Notificacao } from 'src/models/notificacao';
import { Escala } from 'src/models/escala';
import { Agenda } from 'src/models/agenda';
import { Senha } from 'src/models/senha';
import { InfoPagePage } from 'src/pages/info-page/info-page';
import { Comunicacao } from 'src/models/comunicacao';
import { Tabelas } from 'src/models/tabelas';
import { NewComunicacao } from 'src/models-new/new-comunicacao';
import { Solicitacao } from 'src/models/solicitacao';
import { ConsumoComb } from 'src/models/consumo-comb';
import { Contato } from 'src/models/contato';
import { Diretrizes } from 'src/models/diretrizes';
import { Folha } from 'src/models/folha';
import { ExtratoBancoHoras } from 'src/models/extrato-bh';
import { ValeRefeicao } from 'src/models/valeRefeicao';
import { Rendimentos } from 'src/models/rendimentos';
import { Reconhecimentos } from 'src/models/reconhecimentos';
import { RedeSocial } from 'src/models/social';
import { EleicoesCipa } from 'src/models/eleicoes-cipa';
import { SaldoBcoHrs } from 'src/models/saldo-bh';


const keyUser = 'funcionarioLogado';

@Injectable({
  providedIn: 'root'
})
export class FirebaseProvider {
  private PATH_CONFIG = "/Configuracao";
  private PATH_FUNCIONARIOS = "/Usuarios/";
  private PATH_ESCALAS = "/Escala/";
  private PATH_CORREIO = '/Correio/';
  private PATH_VERSAO = '/Versao/';
  private PATH_SOLICITACOES = '/Solicitacoes/';
  private PATH_CONTATOS = '/Contatos/';
  private PATH_FOLHA = '/Folha/';
  private PATH_BCO_HORAS = '/BancoHoras/';
  private PATH_SALDO_BCO_HORAS = '/SaldoBcoHrs/'
  private PATH_VR = '/ValeRefeicao/';
  private PATH_AGENDA_PESSOAL = '/AgendaPessoal/';
  private PATH_ID = '/ListaId/';
  private PATH_READ_ESCALA = '/LeuEscala/';
  private PATH_READ_MSG = '/LeuMsg/';
  private PATH_READ_RECIBO = '/LeuFolhaPgto/';
  private PATH_INFOTRECHO = '/InfoTrecho/';
  private PATH_AVARIAS = '/Avarias/';
  private PATH_IMG_AVARIAS = 'appImagens/ImgAvaria/';
  private PATH_IMG_SOLICITACAO = 'appImagens/ImgSolicitacao/';
  private PATH_COMUNICACAO = '/Comunicacao/';
  private PATH_SENHA = '/TrocaSenha/';
  private PATH_RECUPERA_SENHA = '/RecuperaSenha/';
  private PATH_ALT_SENHA = '/Usuarios/';
  private PATH_INFORMATIVOS = '/Informativos/';
  private PATH_READ_INFORMATIVOS = '/LeuInformativo/';
  private PATH_READ_AGENDA = '/LeuAgenda/';
  private PATH_READ_MANUAL = '/LeuManual/';
  private PATH_TOKEN = '/Token/';
  private PATH_USUARIOS = '/Usuarios/';
  private PATH_CONEXAO = '/ConexoesAtivas/';
  private PATH_DIRETRIZ = '/Diretrizes/';
  private PATH_REDE_SOCIAL = '/RedesSociais/';
  private PATH_MEDALHAS = '/Reconhecimentos/';
  private PATH_RENDIMENTOS = '/CartaRendimentos/';
  private PATH_ALT_DADOS = '/NovosDados/';
  private PATH_CONSUMO_DIESEL = '/ConsumoDiesel/'

  private vRetPage = new Subject<any>();

  public vColorBtn: string;
  public vColorMenu: string;
  public vColorMenu2: string;
  public vCssEmps: string;
  public vCssIcon: string;
  public vCssVar: string;
  public vCssVar2: string;
  public vEmpNgs: number;
  public vImgEmp: string;
  public vImgEmp2: string;
  public vImgIcon: string;
  public vNomeApp: string;
  public vVersao: string;
  public about: string;
  public nomeFuncionario: string = '';
  public comPerfil: string = '';
  public comAgenda: string;
  public funcaoFunc: string = '';

  loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  vMenu: BehaviorSubject<Array<Menu>> = new BehaviorSubject<Array<Menu>>([]);
  pageMenu: Array<Menu>;

  funcionarioLogado: Funcionario;
  tasksRef: AngularFireList<any>;
  tasks: Observable<any[]>;

  constructor(private db: AngularFireDatabase, private afStorage: AngularFireStorage, private ahAuth: AngularFireAuth, private storage: Storage,
    private router: Router, private fb: FirebaseX, private firestore: AngularFirestore, public popoverController: PopoverController,
    private alertCtrl: AlertController, private dmgeral: DmgeralService, private plt: Platform, private device: Device) {

    this.getConfig();
    this.loadStorage();
  }

  getConfig() {
    this.vColorBtn = environment.config.style.corEmpresa;
    this.vColorMenu = environment.config.style.corMenu;
    this.vColorMenu2 = environment.config.style.corMenu1;
    this.vCssEmps = environment.config.style.varCSS2;
    this.vCssVar = environment.config.style.varCSS;
    this.vCssVar2 = environment.config.style.varCSS2;
    this.vEmpNgs = environment.config.empresa.emp;
    this.vImgEmp = environment.config.style.logoEmpresa;
    this.vNomeApp = environment.config.empresa.nomeApp;
    this.vVersao = environment.config.empresa.versaoApp;

    if (environment.config.empresa.emp == 4) {
      this.vImgIcon = environment.config.style.iconeApp2
      this.vCssIcon = 'i-visate';
    } else {
      this.vImgIcon = environment.config.style.iconeApp;
      this.vCssIcon = 'i-default';
    }

    if (environment.config.empresa.emp == 2) {
      this.vImgEmp2 = environment.config.style.logoEmpresa2;
    }


    this.getMenu().subscribe(m => {
      m.forEach(p => {
        if (p.title == 'Agenda' && p.acesso == 'S') {
          this.comAgenda == 'S';
        }
      });
    });
  }

  async loadStorage() {
    this.ahAuth.authState.subscribe((user) => {
      if (user) {
        localStorage.setItem('tokenAuth', JSON.stringify(user))
        this.storage.get(keyUser).then((val) => {
          if (val != undefined && val != 'null') {
            this.funcionarioLogado = JSON.parse(val);
            this.nomeFuncionario = this.funcionarioLogado.nome;
            this.funcaoFunc = this.funcionarioLogado.funcao;
            this.comPerfil = this.funcionarioLogado.comPerfil;
            this.loggedIn.next(true)
          }
        });
      } else {
        localStorage.setItem('tokenAuth', null);
        this.loggedIn.next(false);
      }
    });
  }

  autenticacao(credenciais: { cpf, senha }) {
    this.dmgeral.abreLoading();
    let newCPF = this.dmgeral.pad(credenciais.cpf, 11).replace(/[^\d]+/g, "");
    let email = newCPF + environment.config.empresa.dominioEmp;
    let newKey = newCPF;
    this.getId(newCPF).then(id => {
      if (id.acesso != 'S') {
        this.dmgeral.fechaLoading();
        this.dmgeral.abreAlerta('Atenção!', 'CPF não cadastrado.');
      } else {
        this.ahAuth.signInWithEmailAndPassword(email, newKey).then(autentica => {
          this.login(newCPF, credenciais.senha);
        }).catch(err => {
          this.ahAuth.createUserWithEmailAndPassword(email, newKey).then(novo => {
            this.login(newCPF, credenciais.senha);
          });
        });
      }
    });
  }

  login(cpf: string, senha: string) {
    this.getVersao().then(v => {
      if (this.vVersao < v.versao) {
        this.dmgeral.abreAlerta('Versão não atualizada.', 'Por favor, atualize o seu aplicativo.');
      } else {
        this.getFuncionario(cpf).then(funcionario => {
          let key = '';
          if (environment.config.empresa.emp === 3) {
            key = funcionario.senha;
          } else {
            key = funcionario.senha.substr(10, 11);
          }

          if (funcionario.cpf == cpf && key == senha) {
            this.setFuncionarioLogado(funcionario);
            this.getToken(funcionario.codEmpresa, funcionario.cpf, funcionario.registro);
            this.setLogPage2(funcionario.codEmpresa, 'LoginPage', funcionario.cpf, funcionario.registro, this.device.platform, this.device.model, this.device.version);
            this.nomeFuncionario = this.funcionarioLogado.nome;
            this.funcaoFunc = this.funcionarioLogado.funcao;
            this.comPerfil = this.funcionarioLogado.comPerfil;
            this.loggedIn.next(true);
            this.router.navigateByUrl('/home', { replaceUrl: true });
            this.dmgeral.fechaLoading();
          } else {
            this.dmgeral.fechaLoading();
            this.dmgeral.abreAlerta('Atenção!', 'Usuário ou senha inválidos, repita a autenticação.');
          }
        });
      }
    });
  }

  getToken(empresa: string, idFirebase: string, registro: string) {
    if (this.plt.is("android") || this.plt.is("ios")) {
      if (idFirebase != '') {
        this.fb.getToken().then(token => {
          if (token != undefined) {
            this.setAddToken(empresa, idFirebase, token, registro);
            this.upTokenUser(idFirebase, token);
          }
        }).catch(err => {
          console.log('serviço não disponível.');
        });
        this.fb.onMessageReceived().subscribe(data => {
          if (data.wasTapped) {
            console.log("Received in background");
          } else {
            console.log("Received in foreground");
          };
        });
      }
    } else {
      console.log('não é mobile');
    }
  }

  exit() {
    this.loggedIn.next(false);
    this.router.navigate(['']);;
    this.ahAuth.signOut();
    return this.storage.remove(keyUser);
  }

  async fechaAPP() {
    const alert = await this.alertCtrl.create({
      header: 'Fechar o APP',
      message: 'Você realmente deseja sair?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Sair',
          handler: () => {
            this.exit();
          }
        }
      ]
    });
    await alert.present();
  }

  savePage(data: any) {
    this.vRetPage.next(data);
  }

  getRetPage(): Subject<any> {
    return this.vRetPage;
  }

  async presentPopover(sobre: string) {
    const popover = await this.popoverController.create({
      component: InfoPagePage,
      componentProps: {
        textoPage: sobre
      },
      cssClass: 'custom-popover'
    });
    return await popover.present();
  }

  //funçoes de storage
  setIdAuth(value) {
    this.storage.set('tokenId', value);
  }

  setFuncionarioLogado(value) {
    this.storage.set(keyUser, JSON.stringify(value));
    this.funcionarioLogado = value;

  }

  getFuncionarioLogado() {
    return this.funcionarioLogado;
  }

  tokenMobile: string;
  setTokenStorage(value) {
    this.storage.set('tokenMobile', JSON.stringify(value));
    this.storage.get('tokenMobile').then((val) => {
    });
    this.tokenMobile = value;
  }

  getTokenStorage() {
    return this.tokenMobile;
  }

  //funções firebase
  //get == busca

  getInfoPagina(url: string) {
    this.getMenu().subscribe(menu => {
      this.pageMenu = menu;
      if (this.pageMenu != undefined && this.pageMenu != undefined) {
        this.pageMenu.forEach(pm => {
          if (pm.url == url) {
            this.about = pm.sobre;
            this.presentPopover(this.about);
          }
        });
      }
    });
  }

  getMsgPagina(empresa: string, url: string) {
    this.getAllLista4(empresa, 'MsgMenu', 'Mensagem', 'URL_MENU', url).subscribe(data => {
      if (data.length > 0) {
        let mensagem = '<ion-icon name="alert"></ion-icon> ' + data[0].MENSAGEM;
        this.dmgeral.openTost(mensagem, 5);
      }
    })
  }


  getAllLista(empresaFuncionario: string, path: string, subPath: string): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + path + '/' + subPath + '/')
        .snapshotChanges()
        .pipe(
          map(tk => {
            try {
              return tk.map(c => ({ key: c.payload.key, ...c.payload.val() as {} }));
            } catch (ex) {
              throw 'Erro ao carregar dados do firebase: ' + ex;
            }
          })
        )
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllLista2(empresaFuncionario: string, path: string, subPath: string, campo: string, valor: string): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + path + '/' + subPath + '/',
        ref => ref.orderByChild(campo).equalTo(valor))
        .snapshotChanges()
        .pipe(
          map(tk => {
            try {
              return tk.map(c => ({ key: c.payload.key, ...c.payload.val() as {} }));
            } catch (ex) {
              throw 'Erro ao carregar dados do firebase: ' + ex;
            }
          })
        )
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllLista3(empresaFuncionario: string, path: string, subPath: string) {
    this.tasksRef = this.db.list(empresaFuncionario + "/" + path + '/' + subPath + '/');
    this.tasks = this.tasksRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
    return this.tasks;
  }

  getAllLista4(empresaFuncionario: string, path: string, subPath: string, campo: string, valor: string) {
    this.tasksRef = this.db.list(empresaFuncionario + "/" + path + '/' + subPath + '/',
      ref => ref.orderByChild(campo).equalTo(valor));
    this.tasks = this.tasksRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
    return this.tasks;
  }


  getMenu() {
    let vLista: Observable<Menu[]>;
    this.tasksRef = this.db.list(this.PATH_CONFIG + environment.config.empresa.versaoMenu + "/Menu/");
    vLista = this.tasksRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
    return vLista;
  }

  getId(cpf: string): Promise<Id> {
    return new Promise((resolve, reject) => {
      this.db.object(this.PATH_ID + cpf)
        .snapshotChanges()
        .pipe(
          map(id => ({ key: id.payload.key, ...id.payload.val() as Id }))
        )
        .subscribe(
          data => {
            resolve(this.adaptId(data));
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getVersao(): Promise<Versao> {
    return new Promise((resolve, reject) => {
      this.db.object(this.PATH_VERSAO + '/Aplicativo/')
        .snapshotChanges()
        .pipe(
          map(id => ({ key: id.payload.key, ...id.payload.val() as Versao }))
        )
        .subscribe(
          data => {
            resolve(this.adaptVersao(data));
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getFuncionario(cpf: string): Promise<Funcionario> {
    return new Promise((resolve, reject) => {
      this.db.object(this.PATH_FUNCIONARIOS + cpf,)
        .snapshotChanges()
        .pipe(
          map(id => ({ key: id.payload.key, ...id.payload.val() as Funcionario }))
        )
        .subscribe(
          data => {
            resolve(this.adaptFuncionario(data));
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getEscalaDia(empresa: string, idFirebase: string, data: string): Promise<Escala> {
    return new Promise((resolve, reject) => {
      let re = /\-/gi;
      let correctDate = data.replace(re, "/");
      this.db.list(empresa + "/" + this.PATH_ESCALAS + idFirebase + "/Datas/",
        ref => ref.orderByChild('Data').equalTo(correctDate))
        .snapshotChanges()
        .pipe(
          map(escala => {
            try {
              return escala.map(c => ({ key: c.payload.key, ...c.payload.val() as Escala }));
            } catch (ex) {
              throw 'Erro ao carregar dados de ESCALA DO DIA DO FUNCIONÁRIO do Firebase: ' + ex;
            }
          })
        )
        .pipe(map(this.adaptEscala))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllEscalas(empresaFuncionario: string, idFirebase: string): Promise<Array<Escala>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_ESCALAS + idFirebase + "/Datas/")
        .snapshotChanges()
        .pipe(
          map(escalas => {
            try {
              return escalas.map(c => ({ key: c.payload.key, ...c.payload.val() as Escala }));
            } catch (ex) {
              throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
            }
          })
        )
        .pipe(
          map(this.adaptListaEscalas)
        )
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllCorreio(empresaFuncionario: string, idFirebase: string): Promise<Array<Correio>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_CORREIO + idFirebase + "/Mensagens/")
        .snapshotChanges()
        .pipe(
          map(mensagens => {
            try {
              return mensagens.map(c => ({ key: c.payload.key, ...c.payload.val() as Correio }));
            } catch (ex) {
              throw 'Erro ao carregar as mensagens do funcionário do Firebase: ' + ex;
            }
          })
        )
        .pipe(map(this.adaptListaCorreio))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAgendaPessoal(empresa: string, idFirebase: string): Promise<Array<Agenda>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresa + '/' + this.PATH_AGENDA_PESSOAL + idFirebase + "/Meses/")
        .snapshotChanges()
        .pipe(
          map(ageGeral => {
            try {
              return ageGeral.map(c => ({ key: c.payload.key, ...c.payload.val() as Agenda }));
            } catch (ex) {
              throw 'Erro ao carregar dados da agenda geral ' + ex;
            }
          })
        )
        .pipe(
          map(this.adaptListaAgendaPessoal)
        )
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getSenhaPessoal(empresa: string, idFirebase: string): Promise<Array<Senha>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresa + '/' + this.PATH_SENHA + idFirebase)
        .snapshotChanges()
        .pipe(
          map(pass => {
            try {
              return pass.map(c => ({ key: c.payload.key, ...c.payload.val() as Senha }));
            } catch (ex) {
              throw 'Erro ao carregar os dados' + ex;
            }
          })
        )
        .pipe(
          map(this.adaptListaSenhaPessoal)
        )
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllInformativos(empresaFuncionario: string): Promise<Array<Noticias>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + this.PATH_INFORMATIVOS + 'Cards/')
        .snapshotChanges()
        .pipe(map(i => {
          try {
            return i.map(c => ({ key: c.payload.key, ...c.payload.val() as Noticias }));
          } catch (ex) {
            throw 'Erro ao carregar as mensagens da empresa do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaInformativos))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllComunicacao(empresaFuncionario: string, idFirebase: string): Promise<Array<Comunicacao>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_COMUNICACAO + idFirebase)
        .snapshotChanges()
        .pipe(
          map(c => {
            try {
              return c.map(c => ({ key: c.payload.key, ...c.payload.val() as Comunicacao }));
            } catch (ex) {
              throw 'Erro ao carregar dados do Firebase: ' + ex;
            }
          })
        )
        .pipe(
          map(this.adaptListaComunicacao)
        )
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllTabelas(empresaFuncionario: string, tabela: string): Promise<Array<Tabelas>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/Tabelas/Tabela/", ref => ref.orderByChild('TABELA').equalTo(tabela))
        .snapshotChanges()
        .pipe(map(tk => {
          try {
            return tk.map(c => ({ key: c.payload.key, ...c.payload.val() as Tabelas }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaTabelas))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllSolicitacoes(empresaFuncionario: string, idFirebase: string): Promise<Array<Solicitacao>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_SOLICITACOES + idFirebase + "/Datas/")
        .snapshotChanges()
        .pipe(map(solicitacao => {
          try {
            return solicitacao.map(c => ({ key: c.payload.key, ...c.payload.val() as Solicitacao }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaSolicitacao))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getConsumoComb(empresaFuncionario: string, idFirebase: string): Promise<Array<ConsumoComb>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + this.PATH_CONSUMO_DIESEL + idFirebase + "/Veiculos/")
        .snapshotChanges()
        .pipe(map(bco => {
          try {
            return bco.map(c => ({ key: c.payload.key, ...c.payload.val() as ConsumoComb }));
          } catch (ex) {
            throw 'Erro ao carregar dados do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaConsumoComb))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllContatos(empresaFuncionario: string): Promise<Array<Contato>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_CONTATOS + "/Telefone/")
        .snapshotChanges()
        .pipe(map(contato => {
          try {
            return contato.map(c => ({ key: c.payload.key, ...c.payload.val() as Contato }));
          } catch (ex) {
            throw 'Erro ao carregar as mensagens da empresa do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaContatos))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllDiretrizes(empresaFuncionario: string): Promise<Array<Diretrizes>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + this.PATH_DIRETRIZ + 'Diretriz/')
        .snapshotChanges()
        .pipe(map(tk => {
          try {
            return tk.map(c => ({ key: c.payload.key, ...c.payload.val() as Diretrizes }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaDiretriz))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllFolha(empresaFuncionario: string, idFirebase: string): Promise<Array<Folha>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_FOLHA + idFirebase + "/Meses/")
        .snapshotChanges()
        .pipe(map(folha => {
          try {
            return folha.map(c => ({ key: c.payload.key, ...c.payload.val() as Folha }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaFolha))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllBancoHoras(empresaFuncionario: string, idFirebase: string): Promise<Array<ExtratoBancoHoras>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_BCO_HORAS + idFirebase + "/Meses/")
        .snapshotChanges()
        .pipe(map(bco => {
          try {
            return bco.map(c => ({ key: c.payload.key, ...c.payload.val() as ExtratoBancoHoras }));
          } catch (ex) {
            throw 'Erro ao carregar dados  do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaBancoHoras))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getSaldoBcoHrs(empresaFuncionario: string, idFirebase: string): Promise<Array<SaldoBcoHrs>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + this.PATH_SALDO_BCO_HORAS + idFirebase + '/Saldo/')
        .snapshotChanges()
        .pipe(map(bco => {
          try {
            return bco.map(c => ({ key: c.payload.key, ...c.payload.val() as SaldoBcoHrs }));
          } catch (ex) {
            throw 'Erro ao carregar dados  do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaSalcoBcoHrs))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllValeRefeicao(empresaFuncionario: string, idFirebase: string): Promise<Array<ValeRefeicao>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_VR + idFirebase + "/Meses/")
        .snapshotChanges()
        .pipe(map(vr => {
          try {
            return vr.map(c => ({ key: c.payload.key, ...c.payload.val() as ValeRefeicao }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaVr))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllRendimentos(empresaFuncionario: string, idFirebase: string): Promise<Array<Rendimentos>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_RENDIMENTOS + idFirebase + "/Rendimentos/")
        .snapshotChanges()
        .pipe(map(rendimentos => {
          try {
            return rendimentos.map(c => ({ key: c.payload.key, ...c.payload.val() as Rendimentos }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaRendimentos))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllReconhecimentos(empresaFuncionario: string, idFirebase: string): Promise<Array<Reconhecimentos>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_MEDALHAS + idFirebase + "/Medalhas/")
        .snapshotChanges()
        .pipe(map(medalhas => {
          try {
            return medalhas.map(c => ({ key: c.payload.key, ...c.payload.val() as Reconhecimentos }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaMedalhas))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllRedesSociais(empresaFuncionario: string): Promise<Array<RedeSocial>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_REDE_SOCIAL + '/Redes/')
        .snapshotChanges()
        .pipe(map(tk => {
          try {
            return tk.map(c => ({ key: c.payload.key, ...c.payload.val() as RedeSocial }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaRedeSocial))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getEleicoesCipa(empresa: string, data: string): Promise<EleicoesCipa> {
    return new Promise((resolve, reject) => {
      this.db.list(empresa + "/EleicoesCipa/Eleicao/",
        ref => ref.orderByChild('DATA').equalTo(data))
        .snapshotChanges()
        .pipe(
          map(escala => {
            try {
              return escala.map(c => ({ key: c.payload.key, ...c.payload.val() as EleicoesCipa }));
            } catch (ex) {
              throw 'Erro ao carregar dados do Firebase: ' + ex;
            }
          })
        )
        .pipe(map(this.adaptEleicoes))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  //adapt
  adaptId(data): Id {
    let uid = Id.adapts(data);
    return uid;
  }

  adaptVersao(data): Versao {
    let ver = Versao.adapts(data);
    return ver;
  }

  adaptFuncionario(data): Funcionario {
    let f = Funcionario.adapts(data);
    return f;
  }

  adaptEscala(data): Escala {
    let escala = null;
    if (data.length > 0)
      escala = Escala.adapts(data[0]);
    return escala;
  }

  adaptListaCorreio(data): Array<Correio> {
    let list = new Array<Correio>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Correio.adapts(l));
      });
    }
    return list;
  }

  adaptListaAgendaPessoal(data): Array<Agenda> {
    let list = new Array<Agenda>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Agenda.adapts(l));
      });
    }
    return list;
  }

  adaptListaSenhaPessoal(data): Array<Senha> {
    let list = new Array<Senha>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Senha.adapts(l));
      });
    }
    return list;
  }

  adaptListaEscalas(data): Array<Escala> {
    let list = new Array<Escala>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Escala.adapts(l));
      });
    }
    return list;
  }

  adaptListaInformativos(data): Array<Noticias> {
    let list = new Array<Noticias>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Noticias.adapts(l));
      });
    }
    return list;
  }

  adaptListaComunicacao(data): Array<Comunicacao> {
    let list = new Array<Comunicacao>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Comunicacao.adapts(l));
      });
    }
    return list;
  }

  adaptListaTabelas(data): Array<Tabelas> {
    let list = new Array<Tabelas>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Tabelas.adapts(l));
      });
    }
    return list;
  }

  adaptListaSolicitacao(data): Array<Solicitacao> {
    let list = new Array<Solicitacao>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Solicitacao.adapts(l));
      });
    }
    return list;
  }

  adaptListaConsumoComb(data): Array<ConsumoComb> {
    let list = new Array<ConsumoComb>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(ConsumoComb.adapts(l));
      });
    }
    return list;
  }

  adaptListaContatos(data): Array<Contato> {
    let list = new Array<Contato>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Contato.adapts(l));
      });
    }
    return list;
  }

  adaptListaDiretriz(data): Array<Diretrizes> {
    let list = new Array<Diretrizes>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Diretrizes.adapts(l));
      });
    }
    return list;
  }

  adaptListaFolha(data): Array<Folha> {
    let list = new Array<Folha>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Folha.adapts(l));
      });
    }
    return list;
  }

  adaptListaBancoHoras(data): Array<ExtratoBancoHoras> {
    let list = new Array<ExtratoBancoHoras>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(ExtratoBancoHoras.adapts(l));
      });
    }
    return list;
  }

  adaptListaSalcoBcoHrs(data): Array<SaldoBcoHrs> {
    let list = new Array<SaldoBcoHrs>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(SaldoBcoHrs.adapts(l));
      });
    }
    return list;
  }

  adaptListaVr(data): Array<ValeRefeicao> {
    let list = new Array<ValeRefeicao>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(ValeRefeicao.adapts(l));
      });
    }
    return list;
  }

  adaptListaRendimentos(data): Array<Rendimentos> {
    let list = new Array<Rendimentos>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Rendimentos.adapts(l));
      });
    }
    return list;
  }

  adaptListaMedalhas(data): Array<Reconhecimentos> {
    let list = new Array<Reconhecimentos>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Reconhecimentos.adapts(l));
      });
    }
    return list;
  }

  adaptListaRedeSocial(data): Array<RedeSocial> {
    let list = new Array<RedeSocial>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(RedeSocial.adapts(l));
      });
    }
    return list;
  }

  adaptEleicoes(data): EleicoesCipa {
    let eleicao = null;
    if (data.length > 0)
      eleicao = EleicoesCipa.adapts(data[0]);
    return eleicao;
  }

  //set == adiciona
  setAddToken(empresaFuncionario: string, idFirebase: string, token: string, registro: string) {
    this.db.list(empresaFuncionario + this.PATH_TOKEN + idFirebase).set('0', {
      Token: token,
      Registro: registro
    })
  }

  addReadEscala(empresaFuncionario: string, idFirebase: string, dtLeitura: string) {
    this.db.list(empresaFuncionario + this.PATH_READ_ESCALA + idFirebase + "/").push({
      DataLeitura: dtLeitura
    })
  }

  addReadAgenda(empresaFuncionario: string, idFirebase: string, idEvento: string, dtLeitura: string) {
    this.db.list(empresaFuncionario + this.PATH_READ_AGENDA + idFirebase + '/').push({
      idEvento: idEvento,
      DataLeitura: dtLeitura
    })
  }

  addReadMsg(empresaFuncionario: string, idFirebase: string, idMsg: string, dtLeitura: string) {
    this.db.list(empresaFuncionario + this.PATH_READ_MSG + idFirebase + "/").push({
      IdMsg: idMsg,
      DataLeitura: dtLeitura
    })
  }

  setLogPage2(empresaFuncionario: string, page: string, idFirebase: string, registro: string, sistema: string, modelo: string, versao: string) {
    this.db.database.ref(empresaFuncionario + this.PATH_CONEXAO + '/' + page + '/' + idFirebase).push({
      CPF: idFirebase,
      Registro: registro,
      DataEntrada: this.dmgeral.getDataCompleta(),
      Plataforma: sistema,
      Modelo: modelo,
      VersaoSistema: versao
    })
  }

  setNewNotification(empresa: string, id: string, tipo: string) {
    let objeto: NewNotification = new NewNotification();
    objeto.data = this.dmgeral.getDataAtual();
    objeto.url = this.buscaInfo('url', tipo);
    objeto.texto = this.buscaInfo('txt', tipo);
    objeto.lida = 'false';
    objeto.tipo = tipo;

    this.db.database.ref(empresa + '/Notificacoes/' + id).push(objeto);
  }

  setAddComunicacao(empresa: string, idFirebase: string, objeto: NewComunicacao) {
    var newKey = this.db.database.ref().child('Comunicacao').push().key;
    let objeto2 = {
      DataInclusao: this.dmgeral.getDataCompleta(),
      CPF: idFirebase
    }

    this.db.database.ref(empresa + this.PATH_COMUNICACAO + '/' + idFirebase + '/' + newKey).set(objeto);
    this.db.database.ref(empresa + '/NovasComunicacao/' + 'Novas/' + newKey).set(objeto2);
  }

  setReadReciboPgto(empresaFuncionario: string, idFirebase: string, mes: string, ano: string, tipo: string) {
    this.db.list(empresaFuncionario + this.PATH_READ_RECIBO + idFirebase + "/").push({
      DataLeitura: this.dmgeral.getDataCompleta(),
      Mes: mes,
      Ano: ano,
      Tipo: tipo
    });
  }

  setAddTrecho(empresaFuncionario: string, idFirebase: string, dtInc: string, registro: string, localAprox: string, ptoRef: string, obs: string) {
    this.db.list(empresaFuncionario + '/' + this.PATH_INFOTRECHO + idFirebase + "/").push({
      DataInc: dtInc,
      Registro: registro,
      LocalAprox: localAprox,
      PontoRef: ptoRef,
      Observacao: obs
    })
  }

  addReadManualColab(empresa: string, idFirebase: string, versao: string) {
    this.db.list(empresa + this.PATH_READ_MANUAL + idFirebase + "/").push({
      DataLeitura: this.dmgeral.getDataCompleta(),
      Versao: versao
    })
  }

  setReadPolitica(funcionario: Funcionario) {
    var newKey = this.db.database.ref().child('LeuTermoPrivacidade').push().key;
    let objeto = {
      CPF: funcionario.idFirebase,
      DataLeitura: this.dmgeral.getDataCompleta(),
      Registro: funcionario.registro
    }
    funcionario.lerTermo = 'N'
    this.storage.remove(keyUser);
    this.setFuncionarioLogado(funcionario);
    this.db.database.ref(funcionario.codEmpresa + '/LeuTermoPrivacidade/' + funcionario.idFirebase + "/" + newKey).set(objeto);
    this.db.database.ref(this.PATH_ALT_SENHA + funcionario.idFirebase).update({ Ler_Termo: 'N' });
  }

  addReadInfo(empresaFuncionario: string, idFirebase: string, idMsg: string) {
    var newKey = this.db.database.ref().child('LeuInformativo').push().key;
    let objeto = {
      IdInfo: idMsg,
      DataLeitura: this.dmgeral.getDataCompleta(),
      Data: this.dmgeral.getDataAtual()
    }

    this.db.database.ref(empresaFuncionario + this.PATH_READ_INFORMATIVOS + idFirebase + "/" + newKey).set(objeto);
    this.db.database.ref(empresaFuncionario + '/InformativosLidos/' + idFirebase + "/" + newKey).set(objeto);
  }

  addSol(empresaFuncionario: string, idFirebase: string, tpSol: string, dataf: string, datai: string, dSol: string, dtAb: string, anoFerias: string, mesFerias: string,
    linha: string, local: string, retorno: string, solicitante: string, tab: string) {
    this.db.list(empresaFuncionario + "/" + this.PATH_SOLICITACOES + idFirebase + "/Datas/").push({
      Assunto: tpSol,
      DataF: dataf,
      DataI: datai,
      Descricao: dSol,
      DtAb: dtAb,
      FeriasAno: anoFerias,
      FeriasMes: mesFerias,
      Linha: linha,
      LocasAb: local,
      Retorno: retorno,
      Solicitante: solicitante,
      Tabela: tab
    })
  }

  setRecuperaSenha(cpf: string, recupera) {
    this.db.list(this.PATH_RECUPERA_SENHA + cpf + '/').push({
      RecuperaEmail: recupera
    });
  }

  setAddSenha(empresaFuncionario: string, idFirebase: string, registro: string, novaSenha: string, email: string) {
    if (email != null && email != undefined && email != '') {
      this.db.list(empresaFuncionario + this.PATH_SENHA + idFirebase + "/").push({
        DataTroca: this.dmgeral.getDataCompleta(),
        NovaSenha: novaSenha,
        Registro: registro,
        Email: email
      });
    } else {
      this.db.list(empresaFuncionario + this.PATH_SENHA + idFirebase + "/").push({
        DataTroca: this.dmgeral.getDataCompleta(),
        NovaSenha: novaSenha,
        Registro: registro
      });
    }
  }

  setAddAvaria(empresaFuncionario: string, idFirebase: string, dtInc: string, registro: string, veiculo: string, linha: string, obsGeral: string, descAvaria: string,
    id: string, qtd: number) {
    this.db.list(empresaFuncionario + '/' + this.PATH_AVARIAS + idFirebase + '/').push({
      DataInc: dtInc,
      Registro: registro,
      Veiculo: veiculo,
      Linha: linha,
      ObsGeral: obsGeral,
      DescriAvaria: descAvaria,
      Id: id,
      QtdeImgs: qtd
    })
  }

  //del == delete

  //up == atualiza
  upTokenUser(funcionario: string, newToken: string) {
    this.db.database.ref(this.PATH_USUARIOS + funcionario).update({
      Token: newToken
    });
  }

  upReadMsgFunc(empresaFuncionario: string, funcionario: string, idFirebase: string, lida: string) {
    this.db.database.ref(empresaFuncionario + this.PATH_CORREIO + funcionario + "/Mensagens/" + idFirebase).update({
      Lida: lida
    });
  }

  upReadMsgAlertFunc(empresaFuncionario: string, funcionario: string, idFirebase: string, lida: string, alerta: string) {
    this.db.database.ref(empresaFuncionario + this.PATH_CORREIO + funcionario + "/Mensagens/" + idFirebase).update({
      Lida: lida,
      Alerta: alerta
    });
  }

  upReadEventoFunc(empresaFuncionario: string, idFirebase: string, idEvento: string, lida: string) {
    this.db.database.ref(empresaFuncionario + this.PATH_AGENDA_PESSOAL + idFirebase + '/Meses/' + idEvento).update({
      Lida: lida
    });
  }

  upReadRetEscala(empresaFuncionario: string, funcionario: string, chave: string, lida: string) {
    this.db.database.ref(empresaFuncionario + this.PATH_COMUNICACAO + funcionario + '/' + chave).update({
      lidaResposta: lida
    });
  }

  upReadNotificacao(empresa: string, id: string, chave: string, tipo: string) {
    var newKey = this.db.database.ref().child('LeuNotificacao').push().key;
    let objeto = {
      KeyNotificacao: chave,
      DataLeitura: this.dmgeral.getDataCompleta(),
      Tipo: tipo
    }

    this.db.database.ref(empresa + '/Notificacoes/' + id + '/' + chave).remove();
    this.db.database.ref(empresa + '/LeuNotificacao/' + id + "/" + newKey).set(objeto);
  }

  upDados(empresa: string, idFirebase: string, funcionario: Funcionario) {
    this.db.database.ref(this.PATH_USUARIOS + idFirebase).update({
      Telefone: funcionario.telefone,
      Celular: funcionario.celular,
      Email: funcionario.email,
      DDDTelefone: funcionario.dddFone,
      DDDCelular: funcionario.dddCel,
      Atualizar_Dados: 'N'
    });


    this.db.database.ref(empresa + this.PATH_ALT_DADOS + idFirebase + '/').update({
      Registro: funcionario.registro,
      CPF: funcionario.cpf,
      New_Telefone_DDD: funcionario.dddFone,
      New_Telefone: funcionario.telefone,
      New_Celular_DDD: funcionario.dddCel,
      New_Celular: funcionario.celular,
      New_Email: funcionario.email,
      Data_Troca: this.dmgeral.getDataCompleta()
    });

    this.setFuncionarioLogado(null);
    this.setFuncionarioLogado(funcionario);
  }


  upSenhaUser(funcionario: string, senha: string, situacao: string, email: string) {
    if (email != null && email != undefined && email != '') {
      this.db.database.ref(this.PATH_ALT_SENHA + funcionario).update({
        Senha: senha,
        Trocar_Senha: situacao,
        Email: email
      });
    } else {
      this.db.database.ref(this.PATH_ALT_SENHA + funcionario).update({
        Senha: senha,
        Trocar_Senha: situacao
      });
    }
  }


  //upload
  uploadImageAvaria(imageURI, name: string) {
    let newName = name;
    const storageRef: AngularFireStorageReference = this.afStorage.ref(this.PATH_IMG_AVARIAS);
    return new Promise<any>((resolve, reject) => {
      let imageRef = storageRef.child(newName);
      this.encodeImageUri(imageURI, function (image64) {
        imageRef.putString(image64, 'data_url')
          .then(snapshot => {
            resolve(snapshot.downloadURL)
          }, err => {
            reject(err);
          })
      })
    })
  }

  uploadImageSolicitacao(imageURI, name: string) {
    let newName = name;
    const storageRef: AngularFireStorageReference = this.afStorage.ref(this.PATH_IMG_SOLICITACAO);
    return new Promise<any>((resolve, reject) => {
      let imageRef = storageRef.child(newName);
      this.encodeImageUri(imageURI, function (image64) {
        imageRef.putString(image64, 'data_url')
          .then(snapshot => {
            resolve(snapshot.downloadURL)
          }, err => {
            reject(err);
          })
      })
    })
  }

  encodeImageUri(imageUri, callback) {
    var c = document.createElement('canvas');
    var ctx = c.getContext("2d");
    var img = new Image();
    img.onload = function () {
      var aux: any = this;
      c.width = aux.width;
      c.height = aux.height;
      ctx.drawImage(img, 0, 0);
      var dataURL = c.toDataURL("image/jpeg");
      callback(dataURL);
    };
    img.src = imageUri;
  };



  //funções diversas
  buscaInfo(tpDado: string, page: string) {
    let retorno = '';
    if (tpDado === 'url' && page === 'I') {
      retorno = '/noticias';
    } else if (tpDado === 'url' && page === 'M') {
      retorno = '/mensagem';
    } else if (tpDado == 'url' && page === 'E') {
      retorno = '/comunicacao-consulta';
    } else if (tpDado === 'txt' && page === 'I') {
      retorno = 'Você possui novos informativos.';
    } else if (tpDado === 'txt' && page === 'M') {
      retorno = 'Você possui novas mensagens.';
    } else if (tpDado == 'txt' && page === 'E') {
      retorno = 'Você possui novas respostas na escala.';

    }
    return retorno;
  }

  montaNotificacao(empresa: string, cpf: string) {
    let vInformativos: Array<Noticias>;
    let vInfoLido: Array<any>;
    let vMsgGeral: Array<Correio>;
    let vSolEsc: Array<Comunicacao>;
    const tempM = [];
    let tempI = [];
    const tempE = [];
    var a = [];
    var b = []

    this.getAllInformativos(empresa).then(info => {
      vInformativos = info;
      this.getAllLista(empresa, 'InformativosLidos', cpf).then(async data => {
        vInfoLido = data;

        vInformativos.forEach(i => {
          a.push(i.idNews);
        });

        vInfoLido.forEach(j => {
          b.push(j.IdInfo)
        });

        tempI = a.filter(temp => !b.includes(temp));

        if (tempI.length > 0 && await this.buscaNotificacao(empresa, cpf, 'I') == false) {
          this.setNewNotification(empresa, cpf, 'I');
        }
      });
    });

    this.getAllCorreio(empresa, cpf).then(async func => {
      vMsgGeral = func;
      if (vMsgGeral.length > 0) {
        vMsgGeral.forEach(m => {
          if (m.lida == 'false') {
            tempM.push(m.idMsg);
          }
        });
        if (tempM.length > 0 && await this.buscaNotificacao(empresa, cpf, 'M') == false) {
          this.setNewNotification(empresa, cpf, 'M')
        }
      }
    });

    if (this.vEmpNgs === 4) {
      this.getAllComunicacao(empresa, cpf).then(async comunic => {
        console.log(comunic);
        vSolEsc = comunic;
        if (vSolEsc.length > 0) {
          vSolEsc.forEach(c => {
            if (c.lida == undefined && c.dt_resposta != null && c.dt_resposta != undefined) {
              tempE.push(c.id);
            }
          });
          console.log(tempE)
          if (tempE.length > 0 && await this.buscaNotificacao(empresa, cpf, 'E') == false) {
            this.setNewNotification(empresa, cpf, 'E')
          }

        }
      });
    }
  }

  async buscaNotificacao(empresa: string, cpf: string, tipo: string) {
    let notificacao: Array<Notificacao>;
    return this.getAllLista2(empresa, 'Notificacoes', cpf, 'tipo', tipo).then(data => {
      notificacao = data;
      if (notificacao.length > 0) {
        return true
      } else {
        return false
      }
    });
  }



}