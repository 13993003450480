export class FolhaCtas {
    idFirebase: number; // ID do Firebase (key de acesso)
    conta: string; // Formato para interpretar como hora: "HH:mm"
    nomeConta: string;
    tpCta: string;
    valor: string;
    ref: string;
    vcto: string;
    desc: string;

    // Método construtor do objeto
    constructor(id: number, conta: string, nomeConta: string, tpCta: string, valor: string, ref: string, vcto: string, desc: string) {
        this.idFirebase = id;
        this.conta = conta;
        this.nomeConta = nomeConta;
        this.tpCta = tpCta;
        this.valor = valor;
        this.ref = ref;
        this.vcto = vcto;
        this.desc = desc;
    }
}