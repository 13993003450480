import { ConsumoCombMedias } from './consumo-comb-medias';

export class ConsumoComb {
    idFirebase: string;
    dtIni: string;
    dtFim: string;
    veiculo: string
    placas: string;
    media: string;
    medias: Array<ConsumoCombMedias>;

    constructor(id: string, dtIni: string, dtFim: string, veiculo: string, placas: string, media: string, medias: Array<ConsumoCombMedias>) {
        this.idFirebase = id;
        this.dtIni = dtIni;
        this.dtFim = dtFim;
        this.veiculo = veiculo;
        this.placas = placas;
        this.media = media;
        this.medias = medias;
    }

    static adapts(obj: any): ConsumoComb {
        let idFirebase = obj.key;
        let dtIni = obj.DT_INI;
        let dtFim = obj.DT_FIM;
        let veiculo = obj.VEICULO;
        let placas = obj.PLACAS;
        let media = obj.MEDIA;
        let medias = this.adaptListaMedia(obj.Medias);

        return new ConsumoComb(idFirebase, dtIni, dtFim, veiculo, placas, media, medias);
    }

      static adaptListaMedia(data): Array<ConsumoCombMedias> {
        let list = new Array<ConsumoCombMedias>();
        if (data != null && data != undefined && data.length > 0) {
            data.forEach(l => {
                list.push(this.adatpOpcao(l));
            });
        }
        return list;
    }


    static adatpOpcao(obj: any): ConsumoCombMedias {
        let seq = obj.SEQ;
        let data = obj.DATA;
        let linha = obj.LINHA
        let nomeLinha = obj.NOME_LINHA;
        let veiculo = obj.VEICULO;
        let media = obj.MEDIA;

        return new ConsumoCombMedias(seq, data, linha, nomeLinha, veiculo, media);
    }

}