export class Contato {
    idFirebase: string; // ID do Firebase (key de acesso)
    nome: string;
    nomeReduzido: string;
    fone: string;
    fone1: string;
    fone2: string;
    email: string;
    site: string;
    endereco: string;
    numero: string;
    bairro: string;
    cidade: string;
    uf: string;
    endCompleto: string;
    ruaCompleto: string;
    cep: string;

    constructor(id: string, nome: string, nomeReduzido: string, fone: string, fone1: string, fone2: string, email: string, site: string,
        endereco: string, numero: string, bairro: string, cidade: string, uf: string, endCompleto: string, ruaCompleto: string, cep: string) {

        this.idFirebase = id;
        this.nome = nome;
        this.nomeReduzido = nomeReduzido;
        this.fone = fone;
        this.fone1 = fone1;
        this.fone2 = fone2;
        this.email = email;
        this.site = site;
        this.endereco = endereco;
        this.numero = numero;
        this.bairro = bairro;
        this.cidade = cidade;
        this.uf = uf;
        this.endCompleto = endCompleto;
        this.ruaCompleto = ruaCompleto;
        this.cep = cep;

    }

    static adapts(obj: any): Contato {
        let id = obj.key;
        let nome = obj.Nome;
        let nomeReduzido = obj.Nome_Reduz;
        let fone = obj.Fone1;
        let fone1 = obj.Fone2;
        let fone2 = obj.Fone3;
        let email = obj.Email;
        let site = obj.Site;
        let endereco = obj.Endereco;
        let numero = obj.Numero;
        let bairro = obj.Bairro;
        let cidade = obj.Cidade;
        let uf = obj.Uf;
        let endCompleto = '';
        let ruaCompleto = '';
        let cep = obj.Cep;

        if (obj.Bairro != '') {
            endCompleto = obj.Bairro + ' - ' + obj.Cidade + ' - ' + obj.Uf;
        }

        if (obj.Endereco != '' && obj.Endereco != undefined) {
            ruaCompleto = obj.Endereco + ', ' + obj.Numero;
        }

        return new Contato(id, nome, nomeReduzido, fone, fone1, fone2, email, site, endereco, numero, bairro, cidade, uf, endCompleto, ruaCompleto, cep);
    }

}