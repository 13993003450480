import { Component, OnInit } from '@angular/core';

import { AlertController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { FirebaseProvider } from 'src/provider/firebase';
import { Menu } from 'src/models/menu';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public pages: Array<Menu>;
  public selectedIndex = 0;

  aux: any;
  vColorMenu2 = environment.config.style.corMenu2;
  vComPerfil: string;
  vCssVar4 = environment.config.style.varCSS4;
  vImgEmp: string;
  vMenu1: boolean = false;
  vMenu2: boolean = false;

  constructor(private platform: Platform, private splashScreen: SplashScreen, private statusBar: StatusBar, public firebase: FirebaseProvider,
    private router: Router, private alertCtrl: AlertController) {
    this.initializeApp();
    this.backButtonEvent();

    this.firebase.getRetPage().subscribe((data) => {
      this.aux = data;
    })

    if (this.platform.is('android') || this.platform.is('ios')) {
      this.vMenu2 = true;
      this.vMenu1 = false
    } else {
      this.vMenu1 = true;
      this.vMenu2 = false;
    }

    this.firebase.getMenu().subscribe(m => {
      this.pages = m;
      this.firebase.vMenu.next(this.pages);
    });

    if (environment.config.empresa.emp == 4) {
      this.vImgEmp = environment.config.style.logoEmpresa2;
    } else {
      this.vImgEmp = environment.config.style.logoEmpresa;
    }
  }

  ngOnInit() { }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  backButtonEvent() {
    this.platform.backButton.subscribe(() => {
      if (this.aux != '/login') {
        this.router.navigate([this.aux]);
      } else {
        navigator['app'].exitApp();
      }
    });
  }

  openPage(page, nome: string) {
    if (page.url != null && page.url != undefined && page.url != '') {
      if (nome == 'Avarias') {
        if (this.platform.is('android') || this.platform.is('ios')) {
          //this.router.navigate([page.url]);
          this.router.navigateByUrl(page.url, { replaceUrl: true});
        } else {
          this.alerta2();
        }
      } else {
        //this.router.navigate([page.url]);
        this.router.navigateByUrl(page.url, { replaceUrl: true});
      }
    }
  }

  loggout() {
    this.firebase.fechaAPP();
  }

  openPerfil(){
    console.log('openPerfil')
    this.router.navigate(['/perfil']);

  }

  async alerta2() {
    const alert = await this.alertCtrl.create({
      header: 'Atenção!',
      message: 'Funcionalidade não disponível para esta plataforma.',
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.router.navigate(['/home']);
          }
        }
      ]
    });
    await alert.present();
  }

}
