export class Id {
    idFirebase: string;
    acesso: string;
    email: string;

    constructor(id: string, acesso: string, email: string) {
        this.idFirebase = id;
        this.acesso = acesso;
        this.email = email
    }

    static adapts(obj: any): Id {
        let idFirebase = obj.key;
        let acesso = obj.Acesso;
        let email = obj.Email;

        return new Id(idFirebase, acesso, email);

    }
}