export class Comunicacao {
    idFirebase: string;
    registro: string;
    data: string;
    cpf: string;
    setor: string;
    assunto: string;
    dAssunto: string;
    id: string;
    observacao: string;
    com_imagem: string;
    qtd_imgs: string;
    origem: string;
    resposta_empresa: string;
    dt_resposta: string;
    resposta_usuario: string;
    lida: string;
    link: string;

    constructor(idFirebase: string, registro: string, data: string, cpf: string, setor: string, assunto: string, dAssunto: string, id: string, 
        obs: string, com_imagem: string, qtd_imgs: string, origem: string, resposta_empresa: string, dt_resposta: string, resposta_usuario: string, 
        lida: string, link: string) {
        this.idFirebase = idFirebase;
        this.registro = registro;
        this.data = data;
        this.cpf = cpf;
        this.setor = setor;
        this.assunto = assunto;
        this.dAssunto = dAssunto;
        this.id = id;
        this.observacao = obs;
        this.com_imagem = com_imagem;
        this.qtd_imgs = qtd_imgs;
        this.origem = origem;
        this.resposta_empresa = resposta_empresa;
        this.dt_resposta = dt_resposta;
        this.resposta_usuario = resposta_usuario;
        this.lida = lida;
        this.link = link;
    }

    static adapts(obj: any): Comunicacao {
        let idFirebase = obj.key;
        let registro = obj.registro;
        let data = obj.data;
        let cpf = obj.cpf;
        let setor = obj.setor;
        let assunto = obj.assunto;
        let dAssunto = obj.dAssunto;
        let id = obj.id;
        let observacao = obj.observacao;
        let com_imagem = obj.com_imagem;
        let qtd_imgs = obj.qtd_imgs;
        let origem = obj.origem;
        let resposta_empresa = obj.resposta_empresa;
        let dt_resposta = obj.resposta_data;
        let resposta_usuario = obj.resposta_usuario;
        let lida = obj.lidaResposta;
        let link = obj.resposta_link;

        return new Comunicacao(idFirebase, registro, data, cpf, setor, assunto, dAssunto, id, observacao, com_imagem, qtd_imgs, origem, resposta_empresa,dt_resposta, resposta_usuario, lida, link);
    }


}