import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BrMaskerModule } from 'br-mask';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Badge } from '@ionic-native/badge/ngx';
import { Camera } from '@ionic-native/Camera/ngx';
import { Device } from '@ionic-native/device/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { Screenshot } from '@ionic-native/screenshot/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule} from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { DmgeralService } from 'src/provider/dmgeral';
import { FirebaseProvider } from 'src/provider/firebase';
import { environment } from 'src/environments/environment';
import { AuthGruard } from 'src/guards/auth-gruard';
import { AuthLoginGuard } from 'src/guards/auth-login';
import { ComunicacaoNovoPage } from 'src/pages/comunicacao-novo/comunicacao-novo';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

console.log('Voce esta na empresa: ' + environment.config.empresa.emp);
@NgModule({
  declarations: [AppComponent, ComunicacaoNovoPage],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.config.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    NgxMaskModule.forRoot(maskConfig),
    BrMaskerModule,
  ],
  providers: [
    StatusBar,
    SplashScreen, 
    Badge,
    Camera,
    Device,
    FirebaseX,
    InAppBrowser,
    Screenshot,
    SocialSharing,
    FirebaseProvider,
    DmgeralService,
    AuthGruard,
    AuthLoginGuard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
