import { DtAgenda } from "./dtAgenda";

export class Agenda {
    ano: string;
    mes: string;
    mesExtenso: string;
    datas: Array<DtAgenda>;


    constructor(ano: string, mes: string, mesExtenso: string, datas: Array<DtAgenda>) {
        this.ano = ano;
        this.mes = mes;
        this.mesExtenso = mesExtenso;
        this.datas = datas;
    }

    static adapts(obj: any): Agenda {
        let ano = obj.Ano;
        let mes = obj.Mes;
        let mesExtenso = this.mesExtenso(obj.Mes);
        let datas = this.adaptDatas(obj.Datas);

        return new Agenda(ano, mes, mesExtenso, datas);
    }

    static mesExtenso(mes: string): string {
        let newMes = mes;
        let nome = "--";

        let yearMonth = new Array(12);
        yearMonth[1] = "Janeiro";
        yearMonth[2] = "Fevereiro";
        yearMonth[3] = "Março";
        yearMonth[4] = "Abril";
        yearMonth[5] = "Maio";
        yearMonth[6] = "Junho";
        yearMonth[7] = "Julho";
        yearMonth[8] = "Agosto";
        yearMonth[9] = "Setembro"
        yearMonth[10] = "Outubro";
        yearMonth[11] = "Novembro";
        yearMonth[12] = "Dezembro";

        nome = yearMonth[newMes];

        return nome;
    }

    static mesExtenso2(mes: number): number {
        let newMes = mes;
        let nome = 0;

        let yearMonth = new Array(12);
        yearMonth[1] = "janeiro";
        yearMonth[2] = "fevereiro";
        yearMonth[3] = "março";
        yearMonth[4] = "abril";
        yearMonth[5] = "maio";
        yearMonth[6] = "Junho";
        yearMonth[7] = "julho";
        yearMonth[8] = "agosto";
        yearMonth[9] = "setembro"
        yearMonth[10] = "outubro";
        yearMonth[11] = "novembro";
        yearMonth[12] = "dezembro";

        nome = yearMonth[newMes];

        return nome;
    }
   

    static getWeekDay(data: string): string {
        let newDate = new Date(data);
        let nome = "--";

        let weekdays = new Array(7);
        weekdays[0] = "DOM";
        weekdays[1] = "SEG";
        weekdays[2] = "TER";
        weekdays[3] = "QUA";
        weekdays[4] = "QUI";
        weekdays[5] = "SEX";
        weekdays[6] = "SAB";

        nome = weekdays[newDate.getDay()];

        return nome;
    }

    static getWeekDayExt(data: string): string {
        let newDate = new Date(data);
        let nome = "--";

        let weekdays = new Array(7);
        weekdays[0] = "domingo";
        weekdays[1] = "segunda-feira";
        weekdays[2] = "terça-feira";
        weekdays[3] = "quarta-feira";
        weekdays[4] = "quinta-feira";
        weekdays[5] = "sexta-feira";
        weekdays[6] = "sábado";

        nome = weekdays[newDate.getDay()];

        return nome;
    }

    static getDay(data: string) {
        let newDay = new Date(data);
        let dia = 0;
        dia = newDay.getDate();
        return dia;
    }

    static getMes(data: string) {
        let newMes = new Date(data);
        let mes = 0;
        mes = (newMes.getMonth() + 1);
        return mes;
    }

    static getAno(data: string) {
        let newAno = new Date(data);
        let ano = 0;
        ano = newAno.getFullYear()
        return ano;
    }

    




    static adaptDatas(data): Array<DtAgenda> {
        let list = new Array<DtAgenda>();
        if (data != null && data != undefined && data.length > 0) {
            data.forEach(l => {
                list.push(this.adatpData(l));
            });
        }
        return list;
    }


    static adatpData(obj: any): DtAgenda {
        let data = obj.Data;
        let dt = obj.Data.replace(/re/gi, "-");
        let dia = this.getDay(dt);
        let diaSemana = this.getWeekDay(dt);
        let diaSemanaExt = this.getWeekDayExt(dt);
        let ano = this.getAno(dt);
        let dataCompleta = diaSemanaExt + ', ' + dia + ' de ' + this.mesExtenso2(this.getMes(obj.Data)) +  ' de ' +  ano ;

        let eventos = obj.Eventos;


        return new DtAgenda(data, dia, diaSemana, dataCompleta, eventos);
    }






}