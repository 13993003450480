import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ModalController, ActionSheetController, AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { FirebaseProvider } from 'src/provider/firebase';
import { Camera, PictureSourceType, CameraOptions } from '@ionic-native/Camera/ngx';
import { Storage } from '@ionic/storage';
import { Funcionario } from 'src/models/funcionario';
import { Imagens } from 'src/models/imagens';
import { NewComunicacao } from 'src/models-new/new-comunicacao';
import { NewComunicacaoDet } from 'src/models-new/new-comunicacao-det';
import { DmgeralService } from 'src/provider/dmgeral';
import { TabelasOpcoes } from 'src/models/tabelas-opcoes';
import { Tabelas } from 'src/models/tabelas';

@Component({
  selector: 'app-comunicacao-novo',
  templateUrl: './comunicacao-novo.html',
  styleUrls: ['./comunicacao-novo.scss'],
})
export class ComunicacaoNovoPage implements OnInit {
  dataAtual: string;
  photo: string;
  funcionarioLogado: Funcionario;
  imgs: Array<Imagens> = [];
  imgs2: Array<Imagens>;
  vTabelas: Array<Tabelas>;
  vAssunto: Array<TabelasOpcoes>;
  newComunicado: NewComunicacao = new NewComunicacao();

  constructor(public firebase: FirebaseProvider, private dmgeral: DmgeralService, private modalController: ModalController, public actionSheetController: ActionSheetController,
    private storage: Storage, private camera: Camera, private alertCtrl: AlertController, private cdr: ChangeDetectorRef) {
    this.funcionarioLogado = this.firebase.getFuncionarioLogado();
    this.dataAtual = this.dmgeral.getDataAtual();
  }

  ngOnInit() {
    this.getAssunto();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  getAssunto() {
    this.firebase.getAllTabelas(this.funcionarioLogado.codEmpresa, '506').then(tab => {
      this.vTabelas = tab;
      this.vTabelas.forEach(t => {
        if (t.tabela == '506') {
          this.vAssunto = t.opcoes;
        }
      });
    });
  }

  async selectImage() {
    if (this.imgs.length != 0) {
      this.dmgeral.abreAlerta('Atenção!', 'Imagem já selecionada!');
    } else {
      const actionSheet = await this.actionSheetController.create({
        buttons: [
          {
            text: 'Galeria',
            handler: () => {
              this.takePicture(this.camera.PictureSourceType.PHOTOLIBRARY);
            }
          }
        ]
      });
      await actionSheet.present();
    }
  }

  takePicture(sourceType: PictureSourceType) {
    const options: CameraOptions = {
      quality: 50,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      mediaType: this.camera.MediaType.PICTURE,
      saveToPhotoAlbum: false,
      correctOrientation: true,
      targetWidth: 1800,
      targetHeight: 1200,
    };

    this.camera.getPicture(options).then(imageData => {
      if (sourceType === this.camera.PictureSourceType.PHOTOLIBRARY) {
        this.photo = 'data:image/jpeg;base64,' + imageData;
        if (this.photo != null && this.photo != undefined) {
          this.updateStorage(this.photo)
        }
      }
    });
  }

  updateStorage(temp: string) {
    this.imgs.push({ img: temp });
    this.cdr.detectChanges();
  }

  addFile(id) {
    let idPhoto = id
    let i = 0;
    let nome = '';
    for (i = 0; i < this.imgs.length; i++) {
      nome = idPhoto + 'img_' + i;
      this.firebase.uploadImageSolicitacao(this.imgs[i].img, nome);
    }
  }

  deleteImg(i) {
    const index = this.imgs.indexOf(i);
    if (this.imgs != null && this.imgs != undefined) {
      this.imgs.splice(index, 1);
    }
  }

  async alerta() {
    const alert = await this.alertCtrl.create({
      header: 'Sucesso!',
      message: 'Solicitação cadastrada com sucesso!',
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.closeModal();
          }
        }
      ]
    });
    await alert.present();
  }

  enviaSolicitacao() {
    this.newComunicado.registro = this.funcionarioLogado.registro;
    this.newComunicado.data = this.dmgeral.getDataAtual();
    this.newComunicado.cpf = this.funcionarioLogado.cpf;
    this.newComunicado.setor = '1516';
    this.newComunicado.hr_inc = this.dmgeral.getHora();

    let questions: Array<NewComunicacaoDet> = [];
    this.newComunicado.id = this.funcionarioLogado.idFirebase + this.dmgeral.getDtId();
    this.newComunicado.origem = 'F';
    if (this.imgs.length > 0) {
      this.newComunicado.com_imagem = 'S';
      this.newComunicado.qtd_imgs = this.imgs.length.toString();
    } else {
      this.newComunicado.com_imagem = 'N';
      this.newComunicado.qtd_imgs = '';
    }

    if (this.newComunicado.assunto == null && this.newComunicado.assunto == undefined) {
      this.dmgeral.abreAlerta('Atenção!', 'O assunto não pode ser nulo.');
    } else if (this.newComunicado.observacao == null && this.newComunicado.observacao == undefined) {
      this.dmgeral.abreAlerta('Atenção!', 'O campo observação não pode ser nulo.');
    } else if (this.newComunicado != null && this.newComunicado != undefined) {
      this.firebase.setAddComunicacao(this.funcionarioLogado.codEmpresa, this.funcionarioLogado.idFirebase, this.newComunicado);
      this.addFile(this.newComunicado.id);
      this.alerta();
    }

  }

  getDescricao(asunto: string) {
    if ((asunto != null && asunto != undefined) && (this.vAssunto.length > 0)) {
      this.vAssunto.forEach(a => {
        if (a.codigo === asunto) {
          this.newComunicado.dAssunto = a.descricao;
        }
      });
    }
  }
}