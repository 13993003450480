import { ReconhecimentoDatas } from './reconhecimento-data';

export class Reconhecimentos {
    idFirebase: string;
    codPremio: string;
    titulo: string;
    descricao: string;
    quant: string;
    ultPremio: string;
    urlImg: string;
    datas: Array<ReconhecimentoDatas>;

    constructor(id: string, codPremio: string, titulo: string, descricao: string, quant: string, ultPremio: string, urlImg: string, datas: Array<ReconhecimentoDatas>){
        this.idFirebase = id;
        this.codPremio = codPremio;
        this.titulo = titulo;
        this.descricao = descricao;
        this.quant = quant;
        this.ultPremio = ultPremio;
        this.urlImg = urlImg;
        this.datas = datas;
    }

    static adapts(obj: any): Reconhecimentos {
        let idFirebase = obj.key;
        let codPremio = obj.COD_PREMIO;
        let titulo = obj.TITULO;
        let descricao = obj.DESCRICAO;
        let quant = obj.QUANT;
        let ultPremio = obj.ULT_PREMIO;
        let urlImg = obj.URL_IMAGEM;
        let datas = this.adaptDatas(obj.Datas);

		return new Reconhecimentos(idFirebase, codPremio, titulo, descricao, quant, ultPremio, urlImg, datas);
	}

	static adaptDatas(data): Array<ReconhecimentoDatas> {
		let list = new Array<ReconhecimentoDatas>();
		if (data != null && data != undefined && data.length > 0) {
			data.forEach(l => {
				list.push(this.adaptData(l));
			});
		}
		return list;
	}

	static adaptData(obj: any): ReconhecimentoDatas {
		let idFirebase = obj.key;
		let codPremio = obj.COD_PREMIO;
		let descricao = obj.DESCRICAO;
		let data = obj.DATA;
		let obs = obj.OBS;

		return new ReconhecimentoDatas(idFirebase, codPremio, descricao, data, obs);
	}


}