export class Viagem {

	/*
		PS: O ionic trabalha com hora e data (datetime) usando strings
			Basta a string estar no padrão certo (ver abaixo).
	*/

	idFirebase: number; // ID do Firebase (key de acesso)
	horaInicioViagem: string; // Formato para interpretar como hora: "HH:mm"
	linha: string;
	saida: string;
	sentido: string;
	viagem: string;
	htPt1: string;
	htPt2: string;
	htPt3: string;
	htPt4: string;
	dPt1: string;
	dPt2: string;
	dPt3: string;
	dPt4: string;
	nomeLinha: string;
	letreiro: string;
	veiculo: string;
	apdVgm: string;

	// Método construtor do objeto
	constructor(id: number, horaInicioViagem: string, linha: string, saida: string, sentido: string, viagem: string, 
		htPt1: string, htPt2: string, htPt3: string, htPt4: string, dPt1: string, dPt2: string, dPt3: string, dPt4: string, nomeLinha: string, letreiro: string, veiculo: string, apdVgm: string) {
		this.idFirebase = id;
		this.horaInicioViagem = horaInicioViagem;
		this.linha = linha;
		this.saida = saida;
		this.sentido = sentido;
		this.viagem = viagem;
		this.htPt1 = htPt1;
		this.htPt2 = htPt2;
		this.htPt3 = htPt3;
		this.htPt4 = htPt4;
		this.dPt1 = dPt1;
		this.dPt2 = dPt2;
		this.dPt3 = dPt3;
		this.dPt4 = dPt4;
		this.nomeLinha = nomeLinha;
		this.letreiro = letreiro;
		this.veiculo = veiculo;
		this.apdVgm = apdVgm
	}

}