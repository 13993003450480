import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { formatCPF } from '@brazilian-utils/brazilian-utils';
import { Meses } from 'src/models/mes';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DmgeralService {
  isLoading = false;
  meses: Array<Meses>;

  constructor(private router: Router, public toastController: ToastController, private alertCtrl: AlertController, 
    public loadingCtrl: LoadingController, public formBuilder: FormBuilder) { }

  async openTost(message: string, seg: number) {
    const toast = await this.toastController.create({
      message: message,
      duration: seg * 1000,
      cssClass: 'toast-1',
    });
    toast.present();
  }

  async openTostTop(message: string, seg: number) {
    const toast = await this.toastController.create({
      message: message,
      duration: seg * 1000,
      cssClass: 'toast-1',
      position: 'top'
    });
    toast.present();
  }

  async abreAlerta(titulo: string, subTitulo: string) {
    const alert = await this.alertCtrl.create({
      header: titulo,
      subHeader: subTitulo,
      buttons: ['OK']
    });

    await alert.present();
  }

  async abreAlertaToHome(titulo: string, subTitulo: string) {
    const alert = await this.alertCtrl.create({
      header: titulo,
      message: subTitulo,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.router.navigate(['/home']);
          }
        }
      ]
    });
    await alert.present();
  }


  async abreAlertaToLogin(titulo: string, subTitulo: string) {
    const alert = await this.alertCtrl.create({
      header: titulo,
      message: subTitulo,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.router.navigate(['/login']);
          }
        }
      ]
    });
    await alert.present();
  }

  async openLoader() {
    const loading = await this.loadingCtrl.create({
      duration: 1000,
      message: 'Aguarde, carregando...',
      translucent: true,
    });
    return await loading.present();
  }

  async abreLoading() {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      message: 'Aguarde, carregando...',
      translucent: true
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss();
        }
      });
    });
  }

  async fechaLoading() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss();
  }

  openPage(page: string) {
    this.router.navigate([page]);
  }

  pad(palavra: string, size: number): string {
    let s = palavra + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  formatCPF(temp) {
    return formatCPF(temp, { pad: true });
  }

  //funcções de data
  getAno() {
    let date = new Date();
    let ano = date.getFullYear();
    return ano;
  }

  getMes() {
    let date = new Date();
    let mes = (date.getMonth() + 1);
    return mes;
  }

  getArrayMeses() {
    this.meses = [
      { mes: '1', mes_extenso: 'Janeiro' },
      { mes: '2', mes_extenso: 'Fevereiro' },
      { mes: '3', mes_extenso: 'Março' },
      { mes: '4', mes_extenso: 'Abril' },
      { mes: '5', mes_extenso: 'Maio' },
      { mes: '6', mes_extenso: 'Junho' },
      { mes: '7', mes_extenso: 'Julho' },
      { mes: '8', mes_extenso: 'Agosto' },
      { mes: '9', mes_extenso: 'Setembro' },
      { mes: '10', mes_extenso: 'Outubro' },
      { mes: '11', mes_extenso: 'Novembro' },
      { mes: '12', mes_extenso: 'Dezembro' }
    ]
    return this.meses;
  }

  getDataAtual() {
    let date = new Date();
    let hoje = date.getFullYear()
      + '/'
      + ('0' + (date.getMonth() + 1)).slice(-2)
      + '/'
      + ('0' + date.getDate()).slice(-2);
    return hoje;
  }
  

  getData() {
    let date = new Date();
    let hoje =
      ('0' + date.getDate()).slice(-2)
      + ('0' + (date.getMonth() + 1)).slice(-2)
      + date.getFullYear();
    return hoje;
  }

  getDataCompleta() {
    let date = new Date();
    let hoje = ('0' + date.getDate()).slice(-2)
      + '/'
      + ('0' + (date.getMonth() + 1)).slice(-2)
      + '/'
      + date.getFullYear()
      + ' '
      + date.getHours()
      + ':'
      + ('0' + (date.getMinutes())).slice(-2)
    return hoje;
  }

  getDataReduzia() {
    let date = new Date();
    let hoje = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2);
    return hoje;
  }

  getDtAniver(dt: string) {
    let data = new Date(dt);
    let hoje = ('0' + data.getDate()).slice(-2) + '/' + ('0' + (data.getMonth() + 1)).slice(-2);
    return hoje;
  }

  getHora() {
    let date = new Date();
    let hora = date.getHours() + ':' + ('0' + (date.getMinutes())).slice(-2);
    return hora;
  }

  getDtId() {
    let date = new Date();
    let hoje = ('0' + date.getDate()).slice(-2)
      + ('0' + (date.getMonth() + 1)).slice(-2)
      + date.getFullYear()
      + date.getHours()
      + ('0' + (date.getMinutes())).slice(-2)
    return hoje
  }

  getWeekDayExt(data: string): string {
    let newDate = new Date(data);
    let nome = "--";

    let weekdays = new Array(7);
    weekdays[0] = "Domingo";
    weekdays[1] = "Segunda-feira";
    weekdays[2] = "Terça-feira";
    weekdays[3] = "Quarta-feira";
    weekdays[4] = "Quinta-feira";
    weekdays[5] = "Sexta-feira";
    weekdays[6] = "Sábado";

    nome = weekdays[newDate.getDay()];

    return nome;
  }

  validaForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      controle.markAsDirty();
      if (controle instanceof FormGroup) {
        this.validaForm(controle);
      }
    });
  }




}
