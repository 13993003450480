export class NewComunicacao {
    registro: string;
    data: string;
    hr_inc: string;
    cpf: string;
    setor: string;
    assunto: string;
    dAssunto: string;
    id: string; 
    observacao: string;
    com_imagem: string;
    qtd_imgs: string;
    origem: string;
}