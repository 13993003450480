export class RedeSocial {
    idFirebase: string;
    rede_social: string;
    icon: string;
    url: string;
    title: string;
    fone: string;


    constructor(id: string, rede_social: string, icon: string, url: string, title: string, fone: string) {
        this.idFirebase = id;
        this.rede_social = rede_social;
        this.icon = icon;
        this.url = url;
        this.title = title;
        this.fone = fone;
    }

    static adapts(obj: any): RedeSocial {
        let id = obj.KEY;
        let rede_social = obj.REDE_SOCIAL;
        let icon = obj.ICONE;
        let url = '';

        if (obj.REDE_SOCIAL === '7') {
            url = 'https://api.whatsapp.com/send?phone=' + obj.FONE;
        } else {
            url = obj.LINK_REDE;
        }


        let title = '';
        if (obj.TITULO_WHATSAPP != null && obj.TITULO_WHATSAPP != undefined) {
            title = obj.TITULO_WHATSAPP;
        } else {
            title = obj.TITLE;
        }

        let fone = obj.FONE;

        

        return new RedeSocial(id, rede_social, icon, url, title, fone);
    }

}