export class TabelasOpcoes {
    codigo: string;
    descricao: string 

    constructor(codigo: string, descricao: string) {
        this.codigo = codigo;
        this.descricao = descricao;
    }



}