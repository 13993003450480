export class Noticias {
    idNews: string;
    titulo: string;
    obsNews: string;
    linkNews: string;
    tipo: string;
    dTipo: string;
    lida: string;
    data: string;
    dtLeitura: string;

    constructor(id: string, titulo: string, obs: string, link: string, tipo: string, descTipo: string, lida: string, data: string, dtLeitura: string) {
        this.idNews = id;
        this.titulo = titulo;
        this.obsNews = obs;
        this.linkNews = link;
        this.tipo = tipo;
        this.dTipo = descTipo;
        this.lida = lida;
        this.data = data;
        this.dtLeitura = dtLeitura;
    }

    static adapts(obj: any): Noticias {

        let idNews = obj.ID;
        let titulo = obj.Titulo;
        let obsNews = obj.Obs;
        let linkNews = obj.Link;
        let tipo = obj.Tipo;

        let dTipo = '';
        let lida = '';

        if (tipo == 'M') {
            dTipo = 'MOB INFORMA';
        } else if (tipo == 'I') {
            dTipo = 'Informativo Interno';
        }
        
        if (obj.Lida == undefined || obj.Lida == null){
            lida = 'False'
        } else {
            lida = obj.Lida
        }

        let data = obj.Data;
        let dtLeitura = obj.dtLeitura

        return new Noticias(idNews, titulo, obsNews, linkNews, tipo, dTipo, lida, data, dtLeitura);
    }

}