export class Senha {
    idKey: string;
    registro: string;
    dtTroca: string;

    constructor (id: string, registro: string, dtTroca: string) {
        this.idKey = id,
        this.registro = registro,
        this.dtTroca = dtTroca
    }

    static adapts(obj: any): Senha {
        let idKey = obj.key;
        let registro = obj.Registro;
        let dtTroca = obj.DataTroca;

		return new Senha(idKey, registro, dtTroca);
	}

}