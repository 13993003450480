import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGruard } from 'src/guards/auth-gruard';
import { AuthLoginGuard } from 'src/guards/auth-login';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'agenda',
    loadChildren: () => import('../pages/agenda/agenda.module').then(m => m.AgendaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'agenda-detail',
    loadChildren: () => import('../pages/agenda-detail/agenda-detail.module').then(m => m.AgendaDetailPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'avarias',
    loadChildren: () => import('../pages/avarias/avarias.module').then(m => m.AvariasPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'banco-horas',
    loadChildren: () => import('../pages/banco-horas/banco-horas.module').then(m => m.BancoHorasPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'comunicacao-consulta',
    loadChildren: () => import('../pages/comunicacao-consulta/comunicacao-consulta.module').then(m => m.ComunicacaoConsultaPageModule),
    canLoad: [AuthGruard]
  },  
  {
    path: 'comunicacao-interacao',
    loadChildren: () => import('../pages/comunicacao-interacao/comunicacao-interacao.module').then(m => m.ComunicacaoInteracaoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'comunicacao-novo',
    loadChildren: () => import('../pages/comunicacao-novo/comunicacao-novo.module').then(m => m.ComunicacaoNovoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'consulta-horarios',
    loadChildren: () => import('../pages/consulta-horario/consulta-horario.module').then(m => m.ConsultaHorarioPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'consulta-solicitacao',
    loadChildren: () => import('../pages/consulta-solicitacao/consulta-solicitacao.module').then(m => m.ConsultaSolicitacaoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'consumo-diesel',
    loadChildren: () => import('../pages/consumo-diesel/consumo-diesel.module').then(m => m.ConsumoDieselPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'consumo-detail',
    loadChildren: () => import('../pages/consumo-diesel-detail/consumo-diesel-detail.module').then(m => m.ConsumoDieselDetailPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'contato',
    loadChildren: () => import('../pages/contato/contato.module').then(m => m.ContatoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'diretriz',
    loadChildren: () => import('../pages/diretriz/diretriz.module').then(m => m.DiretrizPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'escala',
    loadChildren: () => import('../pages/escala/escala.module').then(m => m.EscalaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'folha-pgto',
    loadChildren: () => import('../pages/folha-pgto/folha-pgto.module').then(m => m.FolhaPgtoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'fundacao',
    loadChildren: () => import('../pages/fundacao/fundacao.module').then(m => m.FundacaoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'home',
    loadChildren: () => import('../pages/home/home.module').then(m => m.HomePageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'img-rec',
    loadChildren: () => import('../pages/img-rec/img-rec.module').then(m => m.ImgRecPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'info-contato',
    loadChildren: () => import('../pages/info-contato/info-contato.module').then(m => m.InfoContatoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'info-page',
    loadChildren: () => import('../pages/info-page/info-page.module').then(m => m.InfoPagePageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'info-trecho',
    loadChildren: () => import('../pages/info-trecho/info-trecho.module').then(m => m.InfoTrechoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'jornal-visate',
    loadChildren: () => import('../pages/jornal-visate/jornal-visate.module').then(m => m.JornalVisatePageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'login',
    loadChildren: () => import('../pages/login/login.module').then(m => m.LoginPageModule),
    canLoad: [AuthLoginGuard]
  },
  {
    path: 'manual-colab',
    loadChildren: () => import('../pages/manual-colab/manual-colab.module').then(m => m.ManualColabPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'mensagem',
    loadChildren: () => import('../pages/mensagem/mensagem.module').then(m => m.MensagemPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'modal-generic',
    loadChildren: () => import('../pages/modal-generic/modal-generic.module').then(m => m.ModalGenericPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'msg-texto',
    loadChildren: () => import('../pages/msg-texto/msg-texto.module').then(m => m.MsgTextoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'noticias',
    loadChildren: () => import('../pages/noticias/noticias.module').then(m => m.NoticiasPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'notificacao',
    loadChildren: () => import('../pages/notificacao/notificacao.module').then(m => m.NotificacaoPageModule),
    canLoad: [AuthGruard]
  }, 
  {
    path: 'nova-solicitacao',
    loadChildren: () => import('../pages/nova-solicitacao/nova-solicitacao.module').then(m => m.NovaSolicitacaoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'perfil',
    loadChildren: () => import('../pages/perfil/perfil.module').then(m => m.PerfilPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'pontos',
    loadChildren: () => import('../pages/pontos/pontos.module').then(m => m.PontosPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'reconhecimento',
    loadChildren: () => import('../pages/reconhecimento/reconhecimento.module').then(m => m.ReconhecimentoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'reconhecimento-detail',
    loadChildren: () => import('../pages/reconhecimento-detail/reconhecimento-detail.module').then(m => m.ReconhecimentoDetailPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'recupera-senha',
    loadChildren: () => import('../pages/recupera-senha/recupera-senha.module').then(m => m.RecuperaSenhaPageModule),
  },
  {
    path: 'sobre',
    loadChildren: () => import('../pages/sobre/sobre.module').then(m => m.SobrePageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'social',
    loadChildren: () => import('../pages/social/social.module').then(m => m.SocialPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'solicitacao',
    loadChildren: () => import('../pages/solicitacao/solicitacao.module').then(m => m.SolicitacaoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'troca-senha',
    loadChildren: () => import('../pages/troca-senha/troca-senha.module').then(m => m.TrocaSenhaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'vale-refeicao',
    loadChildren: () => import('../pages/vale-refeicao/vale-refeicao.module').then(m => m.ValeRefeicaoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'vcto-folha',
    loadChildren: () => import('../pages/vcto-folha/vcto-folha.module').then(m => m.VctoFolhaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'viagens',
    loadChildren: () => import('../pages/viagens/viagens.module').then(m => m.ViagensPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'cipa',
    loadChildren: () => import('../pages/cipa/cipa.module').then( m => m.CipaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'cipa-detail',
    loadChildren: () => import('../pages/cipa-detail/cipa-detail.module').then( m => m.CipaDetailPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'saldo-banco',
    loadChildren: () => import('../pages/saldo-banco/saldo-banco.module').then( m => m.SaldoBancoPageModule),
    canLoad: [AuthGruard]
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
